import { createRouter, createWebHashHistory  } from 'vue-router'
import { useUserStore, useBlockStore } from '@/stores/index.js'
const whiteList = ['/','/markets','/bot','/earn','/account','/bot/:id','/account/login']
const router = createRouter({
  //history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHashHistory(),
  routes: [
    {
      name: 'notFound',
      path: '/:path(.*)+',
      redirect: {
        name: 'index'
      }
    },
    {
      name: 'index',
      path: '/',
      component: () => import('../views/layout/index.vue'),
      meta: {
        title: 'LxApp'
      },
      children: [
        {
          path: '/',
          //也不使用懒加载，先在文件头部提前引入
          component: () => import('../views/home/index.vue'),
        },
        {
          path: '/markets',
          component: () => import('../views/markets/index.vue'),
        },
        {
          path: '/bot',
          component: () => import('../views/bot/index.vue'),
        },
        {
          path: '/earn',
          component: () => import('../views/earn/index.vue'),
        },
        {
          path: '/account',
          component: () => import('../views/account/index.vue'),
        }
      ]
    },
    {
      name: 'bot',
      path: '/bot/:id',
      component: () => import('../views/bot/info.vue'),
      meta: {
        title: 'Bot'
      }
    },
    {
      name: 'trade',
      path: '/bot/trade/:symbol',
      component: () => import('../views/bot/trade.vue'),
      meta: {
        title: 'Bot'
      }
    },    
    {
      name: 'create',
      path: '/bot/create/:id',
      component: () => import('../views/bot/create.vue'),
      meta: {
        title: 'Bot'
      }
    },
    {
      name: 'history',
      path: '/bot/history',
      component: () => import('../views/bot/history.vue'),
      meta: {
        title: 'Bot'
      }
    },  
    {
      name: 'botrecord',
      path: '/bot/record/:id',
      component: () => import('../views/bot/record.vue'),
      meta: {
        title: 'Bot'
      }
    },
    {
      name: 'record',
      path: '/earn/record',
      component: () => import('../views/earn/record.vue'),
      meta: {
        title: 'earm'
      }
    },
    {
      name: 'activate',
      path: '/account/activate',
      component: () => import('../views/account/activate.vue'),
      meta: {
        title: 'Login'
      }
    },
    {
      name: 'referral',
      path: '/account/referral',
      component: () => import('../views/account/referral.vue'),
      meta: {
        title: 'referral'
      }
    },
    {
      name: 'drawlist',
      path: '/account/drawlist',
      component: () => import('../views/account/drawlist.vue'),
      meta: {
        title: 'feedis'
      }
    },
    {
      name: 'setting',
      path: '/account/setting',
      component: () => import('../views/account/setting.vue'),
      meta: {
        title: 'setting'
      }
    },
    {
      name: 'draw',
      path: '/account/draw',
      component: () => import('../views/account/draw.vue'),
      meta: {
        title: 'draw'
      }
    },
    {
      name: 'records',
      path: '/account/records',
      component: () => import('../views/account/records.vue'),
      meta: {
        title: 'records'
      }
    },
    {
      name: 'billcontent',
      path: '/account/billcontent/:id',
      component: () => import('../views/account/billcontent.vue'),
      meta: {
        title: 'billcontent'
      }
    },
    
    {
      name: 'login',
      path: '/account/login',
      component: () => import('../views/account/login.vue'),
      meta: {
        title: 'Login'
      }
    }
  ]
})
router.beforeEach((to, from, next) => {
  //useUserStore, useBlockStore
 
  const userStore = useUserStore()
  const userInfo = userStore.userInfo
  if (whiteList.includes(to.path) || userInfo.au_state == 1 || userInfo.fake_au == 1) {
    next()  
  }else{
    next('/account/login')    
  }

  // const blockStore = useBlockStore()
  // console.log(userInfo.userInfo)
  
})

export default router

