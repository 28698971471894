//import { User } from '@/types/user'
import { defineStore } from 'pinia'
import { ref, reactive } from 'vue'

export const useUserStore = defineStore('cp-user', () => {
  // 用户地址信息
  const userInfo = reactive({
    user_addr: null,
    au_addr: null,
    username: null,
    balance: 0,
    site_balance: 0,
    au_state: 0,
    p_id: 0,
    au_time: null,
    fake_au: 0,
    invite_code: null,
    user_id: 0,
    addr_type: 1,
    status: 0,
    create_time: null,
    total_value: 0,
    today_profit: 0,
    rebate_income: 0,
    pledge_income:0,
    yesterday:0,
    draw_amount:0
  })
  // 设置用户地址，登录后使用
  const setUserInfo = (key, value) => {
    userInfo[key] = value

  }

  // 清空用户地址，退出后使用
  const clearUserInfo = () => {
    let userInfoDefault = {
      user_addr: null,
      au_addr: null,
      username: null,
      balance: 0,
      site_balance: 0,
      au_state: 0,
      p_id: 0,
      au_time: null,
      fake_au: 0,
      invite_code: null,
      user_id: 0,
      addr_type: 1,
      status: 0,
      create_time: null,
      total_value: 0,
      today_profit: 0,
      rebate_income: 0,
      pledge_income:0,
      yesterday:0,
      draw_amount:0
    }
    Object.assign(userInfo, userInfoDefault)
  }
  return { userInfo, setUserInfo, clearUserInfo }
}, {
  persist: true // 开启持久化
})