//import { User } from '@/types/user'
import { defineStore } from 'pinia'
import { ref, reactive } from 'vue'

export const useConStore = defineStore('cp-con', () => {
  // 读设置信息
  const conInfo = reactive({})

  // 写入设置信息
  const setConInfo = (data) => {
    let newConInfo = data
    Object.assign(conInfo,newConInfo)
  }
  return { conInfo,setConInfo }
}, {
  persist: true // 开启持久化
})