//import { User } from '@/types/user'
import { defineStore } from 'pinia'
import { ref, reactive } from 'vue'

export const useBlockStore = defineStore('cp-block', () => { 
  // 链信息              //1:tron  2:ether 3bnb  0：无
  const blockInfo = reactive({ chain: 0 })
  // 设置链，登录后使用
  const setBlockInfo = (key,value) => {
    blockInfo[key] = value
  }
  // 清空链
  const delblockInfo = () => {
    blockInfo.chain = 0
  }
  return { blockInfo, setBlockInfo, delblockInfo }
}, {
  persist: true // 开启持久化
})