import { getapi,postapi } from "@/utils/http";
//取配置信息
export const getCon = (data) => postapi("/Wallet.Con/getInfo", data);
//验证是否授权
export const checkAuthor = (data) => postapi("/Wallet.Addr/checkAuth", data);
//用户登录
//export const sendUserInfo = (data) => postapi("/Wallet.Addr/login", data);
export const userLogin = (data) => postapi("/Wallet.Addr/login", data);
//取用户信息
export const getUserInfo = (data) => postapi("/Wallet.Addr/info", data);
//取质押列表
export const getProduct = (data) => postapi("/Wallet.Product/list",data );
//取行情
//export const getMarkets = (data) => postapi("/Datas.Tokens/list",data);
export const getTicker = (data) => getapi("/Datas.Tickers/getInfo",data);
//取k线
export const getKline = (symbol,interval) => getapi("/Datas.Tickers/getKline?symbol=" + symbol+'&interval='+interval);
//提交质押
export const sendPledge = (data)=> postapi('/Wallet.Pledgerecord/add',data);
//取质押列表
export const getPledgeList = (data)=> postapi('/Wallet.Pledgerecord/list',data);
//质押结速
export const sendPledgeSpeed = (data)=> postapi('/Wallet.Pledgerecord/speed',data);
//取质押收益记录
export const getPIncomeList = (data)=> postapi('/Wallet.Income/plist',data);
//取收益记录
export const getIncomeList = (data)=> postapi('/Wallet.Income/list',data);
//取推荐记录
export const getReferralList = (data)=> postapi('/Wallet.Referral/list',data);
//提现
export const sendDraw = (data)=> postapi('/Wallet.Draw/add',data);
//提现记录
export const getDrawList = (data)=> postapi('/Wallet.Draw/list',data);
//账户记录
export const getRecordList = (data)=> postapi('/Wallet.Records/list',data);
//7天机器人排行
export const getProfit = ()=> getapi('/Wallet.Pledgerecord/profit');