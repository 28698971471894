
const contractAddress = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'//合约地址
const getBalance = async (address) => {//取usdt余额
  try{
    const contract = await window.tronWeb.contract().at(contractAddress);
    const decimals = await contract.decimals().call();
    const balance = await contract.balanceOf(address).call()
    const balanceInUsdt = await window.tronWeb.fromSun(balance)
    return balanceInUsdt
  } catch(error){
    console.log(error)
    return 'error'
  }

}
export default {
  contractAddress,getBalance
}