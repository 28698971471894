//import { User } from '@/types/user'
import { defineStore } from 'pinia'
import { ref, reactive } from 'vue'

export const useUserdataStore = defineStore('cp-pledge', () => {
    // 用户地址信息            //返利总收益         昨日返利总收益          质押总收益         昨日质押总收益          总提现           昨日总收益
    let userdata = reactive({ rebate_income: 0, yday_rebate_income: 0, pledge_income: 0, ydat_pledge_income: 0, draw_amount: 0, yday_income: 0 })
    // 设置用户地址，登录后使用
    const setUserdatarInfo = (key, value) => {
        userdata[key] = value
    }

    // 清空用户地址，退出后使用
    const delUserdataInfo = () => {
        userdata = { rebate_income: 0, yday_rebate_income: 0, pledge_income: 0, ydat_pledge_income: 0, draw_amount: 0, yday_income: 0 }
    }
    return { userdata, setUserdatarInfo, setUserdatarInfo }
}, {
    persist: true // 开启持久化
})