//import { User } from '@/types/user'
import { defineStore } from 'pinia'
import { ref, reactive } from 'vue'

export const useProductStore = defineStore('cp-pledge', () => { 
  // 项目例表
  const productInfo = reactive([])
  // 设置项目例表
 const setProductInfo = (data)=>{

    Object.assign(productInfo,data)
}
  return { productInfo,setProductInfo }
}, {
  persist: true // 开启持久化
})