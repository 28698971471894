import { createApp } from 'vue'
import pinia from './stores'

import App from './App.vue'
import router from './router'

//import echarts from './utils/echarts';
//VueClipboard.config.autoSetContainer = true 

import "bootstrap/dist/css/bootstrap.min.css"
import "@popperjs/core"
import "bootstrap"
import 'vant/lib/index.css';
import './assets/style.css'
import './assets/fireants.css'


const app = createApp(App)
//app.config.globalProperties.$echarts = echarts
app.use(pinia)
app.use(router)
app.mount('#app')
